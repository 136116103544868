import { ReactSVG } from "react-svg";

export const fancyUlTextRenderer = (src, text, color) => (
  <span className="stylish-text inline-block" style={{ color }}>
    {text}
    <ReactSVG
      height="16px"
      width="100%"
      src={src}
      style={{ width: "100%", marginBottom: "8px" }}
      beforeInjection={(svg) => {
        svg.setAttribute("width", "100%");
        // svg.setAttribute("stroke", color);
      }}
      className="fancy-text"
    />
  </span>
);
