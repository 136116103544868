export const navItemHighlighter = (isAnotherPage, href, offset) => {
  const homeLinkEl = document.getElementById("home-link");
  const whatWeDoLinkEl = document.getElementById("what-we-do-link");
  const faqLinkEl = document.getElementById("faq-link");
  const whyWebuiltLinkEl = document.getElementById("why-we-built-link");
  const newsletterEl = document.getElementById("news-letter");
  const parivacyPolicyEl = document.getElementById("privacy-policy");
  newsletterEl?.classList.remove("active");
  parivacyPolicyEl?.classList.remove("active");
  homeLinkEl?.classList.remove("active");
  whatWeDoLinkEl?.classList.remove("active");
  faqLinkEl?.classList.remove("active");
  whyWebuiltLinkEl?.classList.remove("active");
  if (isAnotherPage) {
    if (href === "/newsletter") {
      newsletterEl?.classList.add("active");
    } else if (href === "/privacy-policy") {
      parivacyPolicyEl?.classList.add("active");
    } else if (href === "/about") {
      whyWebuiltLinkEl?.classList.add("active");
    }
  } else {
    parivacyPolicyEl?.classList.remove("active");
    if (offset >= 0 && offset < 1181 && href === "home") {
      homeLinkEl?.classList.add("active");
      whatWeDoLinkEl?.classList.remove("active");
      faqLinkEl?.classList.remove("active");
    } else if (offset >= 1181 && offset < 2604 && href === "what-we-do") {
      homeLinkEl?.classList.remove("active");
      whatWeDoLinkEl?.classList.add("active");
      faqLinkEl?.classList.remove("active");
    } else if (offset >= 2577 && href === "faq") {
      console.log("faq highliter", faqLinkEl, href, offset);
      homeLinkEl?.classList.remove("active");
      whatWeDoLinkEl?.classList.remove("active");
      faqLinkEl?.classList.add("active");
    }
  }
};
