import anonymous from "../Assets/Images/logo.svg";
import indicate from "../Assets/Icons/indicate.svg";
import match from "../Assets/Icons/match.svg";
import icon1 from "../Assets/Icons/employer1.svg";
import icon2 from "../Assets/Icons/employer2.svg";
import icon3 from "../Assets/Icons/employer3.svg";
import icon4 from "../Assets/Icons/employer4.svg";
import icon5 from "../Assets/Icons/employer5.svg";
import icon6 from "../Assets/Icons/employer6.svg";
import icon7 from "../Assets/Icons/employer7.svg";
import icon8 from "../Assets/Icons/employer8.png";
import icon9 from "../Assets/Icons/employer9.png";
import icon10 from "../Assets/Icons/swing.png";
import icon11 from "../Assets/Icons/daversa.jpeg";
import icon12 from "../Assets/Icons/partner.jpeg";
import icon13 from "../Assets/Icons/inflection.png";

export const anonimityCards = [
  {
    icon: anonymous,
    title:
      "Upon approval, complete your anonymous profile or confidential job opportunity",
  },
  {
    icon: indicate,
    title: "Indicate interest on the candidate or job opportunity",
  },
  {
    icon: match,
    title: "Match with exclusive opportunities & leadership talent",
  },
];

export const trustedEmployers = [
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8,
  icon9,
  icon10,
  icon11,
  icon12,
  icon13,
];

export const carouselConfig = [
  {
    text: "1Stealth Jobs adds a layer of precision to our executive recruitment efforts. The exclusive access to high-caliber candidates and the platform's commitment to anonymity make it stand out. The reduction in hiring biases aligns with our values, creating a positive impact on our recruitment outcomes. Stealth Jobs is the place we proactively source for backfills, opportunistic hires, and confidential executive searches.",
    name: "David",
    position: "Senior Executive",
    profile: "Recruiter",
  },
  {
    text: "2Stealth Jobs adds a layer of precision to our executive recruitment efforts. The exclusive access to high-caliber candidates and the platform's commitment to anonymity make it stand out. The reduction in hiring biases aligns with our values, creating a positive impact on our recruitment outcomes. Stealth Jobs is the place we proactively source for backfills, opportunistic hires, and confidential executive searches.",
    name: "Sarah",
    position: "Senior Executive",
    profile: "Job seeker",
  },
  {
    text: "3Stealth Jobs adds a layer of precision to our executive recruitment efforts. The exclusive access to high-caliber candidates and the platform's commitment to anonymity make it stand out. The reduction in hiring biases aligns with our values, creating a positive impact on our recruitment outcomes. Stealth Jobs is the place we proactively source for backfills, opportunistic hires, and confidential executive searches.",
    name: "Sarah",
    position: "Senior Executive",
    profile: "Job seeker",
  },
  {
    text: "4Stealth Jobs adds a layer of precision to our executive recruitment efforts. The exclusive access to high-caliber candidates and the platform's commitment to anonymity make it stand out. The reduction in hiring biases aligns with our values, creating a positive impact on our recruitment outcomes. Stealth Jobs is the place we proactively source for backfills, opportunistic hires, and confidential executive searches.",
    name: "David",
    position: "Senior Executive",
    profile: "Recruiter",
  },
];

export const mobileNavMenuItems = [
  { label: "Home", link: "/#home" },
  { label: "What We Do", link: "/#what-we-do" },
  { label: "FAQs", link: "/#faq" },
  { label: "About", link: "/about" },
  { label: "Newsletter", link: "/newsletter" },
];

export const faq_Config = [
  {
    id: 1,
    heading: "What is Stealth Jobs?",
    text: "Stealth Jobs is a transformative solution that tackles the challenges inherent in leadership recruitment. It is not just a job board, but a platform that provides exclusive access to a curated list of hidden opportunities for job seekers, especially leaders while ensuring confidentiality for both parties.",
  },
  {
    id: 2,
    heading: "How does Stealth Jobs benefit leaders?",
    text: "Stealth Jobs delivers unparalleled value to job seekers, especially leaders, by providing exclusive access to hidden opportunities. It eliminates the reliance on extensive networking and exclusive outreach, making the job search process more efficient and effective.",
  },
  {
    id: 3,
    heading: "How does Stealth Jobs contribute to reducing hiring biases?",
    text: "Stealth Jobs actively contributes to the reduction of hiring biases by concealing gender and names until a match has been formed. This ensures a fair and impartial evaluation of talent, promoting a more inclusive and unbiased hiring environment.",
  },
  {
    id: 4,
    heading: "How does Stealth Jobs benefit recruiters?",
    text: "Stealth Jobs benefits recruiters by offering heightened efficiency in the hiring process. Recruiters are able to expand their networks to more confidential candidates, reduce search times, and minimize wasted effort in identifying interested executives.",
  },
  {
    id: 5,
    heading: "What makes Stealth Jobs different from other job boards?",
    text: "Stealth Jobs is not just a job board, but a platform that fosters meaningful and confidential connections between top-tier talent and exclusive roles. It revolutionizes the leadership recruitment experience by providing a streamlined process and promoting a more inclusive hiring environment.",
  },
  {
    id: 6,
    heading: "What is the approval criteria to join Stealth Jobs?",
    text: "The approval criteria to join Stealth Jobs is centered around leadership excellence. We seek individuals with a proven track record of leadership experience, notable accomplishments, and alignment within the needs of growth organizations. Our goal is to curate a community of high-caliber professionals who bring exceptional value to the platform, ensuring a secure and exclusive environment for both candidates and companies.",
  },
  {
    id: 7,
    heading: "What does this cost?",
    text: "Stealth Jobs is free to both Leaders and Recruiters",
  },
  {
    id: 8,
    heading: "As a leader, how do I ensure my identity is protected?",
    text: `We take privacy extremely seriously and have mandated a few measures to protect the identity of the leaders as they discreetly search for their next opportunity.

First, Recruiters will not have the ability to view any interested candidates until a Leader has selected that they are interested in the role. Only then will the Recruiter have visibility into the limited profile or full profile of the Leader based on their preferences.

As a leader, you'll have the ability to select if you prefer your profile to be in Stealth or Non-Stealth. If you choose Stealth, then your name, and company name will be hidden from visibility of the employer until there is a match.

Once a match occurs, both parties will have full visibility into the job and Leader profile.`,
  },
  {
    id: 9,
    heading:
      "As a Leader, how do I ensure my company doesn't find out I'm on Stealth Jobs?",
    text: "We take measures into place so that a Leader will be blocked from having visibility into any roles of their past employers so that a match would not be possible.",
  },
  {
    id: 10,
    heading: "As a Recruiter, how do I ensure high quality matches?",
    text: "When completing the job posting, the Recruiter will select preferred job functions and job levels that will only allow individuals that match your requirements to have visibility into the job opportunity.",
  },
];

export const whyWeBuiltStaticText = [
  "Having embarked on a journey to explore a new leadership position, I faced challenges that led to the creation of Stealth Jobs—a solution crafted to optimize the confidential leadership hiring process.",
  "In this exploration, finding available leadership opportunities on the most common job boards proved challenging. Instead of being able to easily identify open roles, I resorted to extensive networking, meeting with over 100 professionals, including executive recruiters, talent partners, venture partners, founders, and executives. Quickly, I realized that this networking was the sole means to discover leadership positions as ~90% of all available leadership roles were confidential and not publicly visible online for qualified candidates to indicate interest. This not only hindered my proactive search for opportunities but also highlighted the significant challenge for more passive or confidential candidates.",
  "Stealth Jobs was born out of this need for a better way—a curated platform providing exclusive access to a hidden list of leadership opportunities that meets your specific criteria. With a key emphasis on confidentiality, both leadership candidates and hiring companies remain anonymous until a mutual match is established. This ensures that passive and opportunistic candidates can explore opportunities with confidence, maintaining anonymity, while also supporting employers with highly confidential roles such as crucial leadership hires, strategic initiatives, sensitive backfills, and more.",
  "Our goal is to introduce efficiency, confidentiality, and inclusivity to the leadership hiring process. By fostering a secure environment  where professionals can explore opportunities and companies have the ability to source active or passive candidates for highly confidential roles, we aim to significantly reduce the hiring timeline from months to weeks, facilitating meaningful connections between leadership candidates and their next opportunity.",
];
