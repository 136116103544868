import { Divider, Drawer, Image, Typography } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../../Assets/Images/logo-2.svg";
import menuIcon from "../../Assets/Icons/mobile-menu.svg";
import { CloseOutlined } from "@ant-design/icons";
import { mobileNavMenuItems } from "../../Utils/staticData";
import MobileNavMenuItem from "./MobileNavMenuItem";

const MobileBar = () => {
  const [isOpenDrawer, setDrawer] = useState(false);
  const openDrawer = () => setDrawer(true);
  const closeDrawer = () => setDrawer(false);
  const isSticky = (e) => {
    const header = document.querySelector(".nav-container-mobile");
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  return (
    <div className="nav-container-mobile is-sticky">
      <div className="logo_wrapper">
        <Image src={logo} width="200px" preview={false} />
      </div>
      <Image src={menuIcon} width="18px" preview={false} onClick={openDrawer} />
      <Drawer
        placement="top"
        closable={false}
        onClose={closeDrawer}
        open={isOpenDrawer}
        height="auto"
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <div className="nav-container-mobile">
          <div className="logo_wrapper">
            <Image src={logo} width="200px" preview={false} />
          </div>
          <CloseOutlined style={{ fontSize: "24px" }} onClick={closeDrawer} />
        </div>
        <Divider />
        {mobileNavMenuItems.map(({ label, link }, index) => (
          <MobileNavMenuItem
            label={label}
            link={link}
            index={index}
            closeDrawer={closeDrawer}
            last={mobileNavMenuItems.length - 1}
          />
        ))}
      </Drawer>
    </div>
  );
};

export default MobileBar;
