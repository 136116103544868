import React, { useEffect, useState } from "react";
import Title from "antd/es/typography/Title";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fancyUlTextRenderer } from "../../Utils/utils";
import stylishUnderline from "../../Assets/Icons/underline-2.svg";
import "./index.scss";
import Paragraph from "antd/es/typography/Paragraph";
import { Button, Form, Input } from "antd";
import { navItemHighlighter } from "../../Utils/helper";
import { useLocation } from "react-router-dom";
import { saveForm } from "../../API/waitlist";

const NewsLetter = () => {
  const { pathname } = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const [form] = Form.useForm();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    navItemHighlighter(true, pathname);
  }, []);
  const initialValues = {
    email: "",
  };
  const onFinish = (values) =>
    saveForm({
      formData: { ...values, name: "test" },
      type: "newsletter",
      setLoading,
      toast,
      toastMessage: "Newsletter Joined",
      setSuccess,
    });

  return (
    <div className="newsletter-container">
      <Title
        level={1}
        style={{ marginTop: "0px", marginBottom: "0" }}
        className="banner_heading"
      >
        Your Gateway to
      </Title>
      <Title
        level={1}
        style={{ marginTop: "0px", marginBottom: "0.5em !important" }}
        className="banner_heading"
      >
        Exclusive{" "}
        {fancyUlTextRenderer(stylishUnderline, "Executive", "#5D5BF6")}{" "}
        Opportunities
      </Title>
      <Title level={4} className="banner_text" style={{ marginTop: "0px" }}>
        Redefining Recruitment with Efficiency, Confidentiality, and Inclusivity
      </Title>
      <div className="newsletter-box">
        <Title
          level={1}
          style={{ marginTop: "0px", marginBottom: "0.5em !important" }}
          className="secondary-text newsletter-heading"
        >
          Unlock exclusive access to hidden opportunities!
        </Title>
        <Paragraph className="newsletter-description">
          Subscribe to our newsletter for confidential leadership roles tailored
          just for you.
        </Paragraph>
        <div className="newsletter-form-container">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              ...initialValues,
              requiredMarkValue: false,
            }}
            requiredMark={false}
          >
            <Form.Item
              label="Email Address"
              rules={[
                {
                  required: true,
                  message: "Please enter your Email",
                },
                {
                  pattern:
                    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
                  message: "Please enter a valid email address",
                },
              ]}
              name="email"
            >
              <Input placeholder="Enter your email address" size="large" />
            </Form.Item>

            <div style={{ width: "100%" }}>
              <Button
                size="large"
                className="contained_button"
                htmlType="submit"
                disabled={isLoading}
              >
                Join our Newsletter
              </Button>
            </div>
          </Form>
        </div>
      </div>
      {/* {isSuccess && <Success setSuccess={setSuccess} />} */}
      <ToastContainer />
    </div>
  );
};

export default NewsLetter;
