import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import Footer from "../../Pages/Home/Components/Footer";

const HomepageLayout = () => {
  const { pathname } = useLocation();
  const hideFooter = () => {
    if (pathname === "/newsletter") {
      return false;
    } else return true;
  };
  console.log("hideFooter", pathname, hideFooter());
  return (
    <div>
      <Navbar />
      <div className="outlet-wrapper">
        <Outlet />
        {hideFooter() && <Footer />}
      </div>
    </div>
  );
};

export default HomepageLayout;
