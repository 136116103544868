import { Button, Divider } from "antd";
import React, { useState } from "react";
import JoinWaitlistModal from "../JoinWaitlist";
import { Link } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../Config/firebase";

const MobileNavMenuItem = ({ label, link, index, closeDrawer, last }) => {
  const [isModalOpen, setIsModalOpen] = useState({
    variant: "seeker",
    state: false,
  });

  const handleOk = (c) => setIsModalOpen({ variant: "seeker", state: false });
  const handleCancel = () =>
    setIsModalOpen({ variant: "seeker", state: false });
  const handleClicker = (type) => {
    setIsModalOpen({ variant: type, state: true });
    logEvent(analytics, `${type}_onboarding_started`);
  };

  return (
    <>
      <div className="mobile-nav-menu-item-container">
        <Link to={link}>
          <p
            className="mobile-nav-menu-item text-condensed"
            onClick={closeDrawer}
          >
            {label}
          </p>
        </Link>
        {index === last ? (
          <div className="nav-actions">
            <button
              size="large"
              className="contained_button"
              onClick={() => handleClicker("seeker")}
            >
              I'm a Leader
            </button>{" "}
            <button
              type="primary"
              className="outlined_button"
              size="large"
              onClick={() => handleClicker("employer")}
            >
              I'm Recruiting
            </button>
          </div>
        ) : (
          <Divider />
        )}
      </div>
      <JoinWaitlistModal
        isModalOpen={isModalOpen.state}
        handleOk={handleOk}
        handleCancel={handleCancel}
        defaultValue={isModalOpen.variant}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default MobileNavMenuItem;
