import { Card } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import "./index.scss";
import arrow from "../../../../Assets/Icons/arrow.svg";
import blueArrow from "../../../../Assets/Icons/blueArrow.svg";
import stylishUnderline from "../../../../Assets/Icons/underline-2.svg";

import DiscoverImg from "../../../../Assets/Images/discoverImg.svg";
import JoinWaitlistModal from "../../../../Components/JoinWaitlist";
import { fancyUlTextRenderer } from "../../../../Utils/utils";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../Config/firebase";

const Discover = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    variant: "seeker",
    state: false,
  });
  const handleClicker = (type) => {
    console.log("handle clicker", type);
    setIsModalOpen({ variant: type, state: true });
    logEvent(analytics, `${type}_onboarding_started`);
  };
  const handleOk = () => setIsModalOpen({ variant: "seeker", state: false });
  const handleCancel = () =>
    setIsModalOpen({ variant: "seeker", state: false });

  return (
    <div className="discover-wrapper">
      <div className="discover-container">
        <Card className="discover-card">
          <div className="discover_leftCard">
            <Title level={1} className="discover_heading">
              Discover Your Next
            </Title>
            <Title level={1} className="discover_heading">
              <span className="secondary-text">Leadership</span> Role
            </Title>
            <Title level={4} className="discover_text">
              Apply to Stealth Jobs and gain exclusive access to a curated list
              of hidden opportunities for leaders. Say goodbye to extensive
              networking and exclusive outreach.
            </Title>
            <div className="banner-btn-wrapper" style={{ marginTop: "40px" }}>
              <button
                size="large"
                className="contained_button"
                onClick={() => handleClicker("seeker")}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  I’m a Leader
                  <img
                    src={arrow}
                    // preview={false}
                    width="8px"
                    height="25px"
                    style={{ marginLeft: "8px" }}
                    alt="arrow"
                  />
                </div>
              </button>
              <button
                className="outlined_button"
                onClick={() => handleClicker("employer")}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  I’m Recruiting
                  <img
                    src={blueArrow}
                    width="8px"
                    height="25px"
                    style={{ marginLeft: "8px" }}
                    alt="arrow"
                  />
                </div>
              </button>
            </div>
            <div
              id="discover-jtw-container"
              className="flex-center waitlist-join-text"
            >
              <Title
                level={5}
                style={{ marginTop: "15px" }}
                className="banner_text"
              >
                {fancyUlTextRenderer(
                  stylishUnderline,
                  "Join the Waitlist",
                  "#5d5bf6"
                )}
              </Title>
            </div>
          </div>
          <div className="discover_rightCard">
            <img src={DiscoverImg} alt="" />
          </div>
        </Card>
        <JoinWaitlistModal
          isModalOpen={isModalOpen.state}
          handleOk={handleOk}
          handleCancel={handleCancel}
          defaultValue={isModalOpen.variant}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </div>
  );
};

export default Discover;
