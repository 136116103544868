import React from "react";
import success from "../../Assets/Images/success.svg";
import Title from "antd/es/typography/Title";
import { Button } from "antd";
import Link from "antd/es/typography/Link";

const Success = ({ setSuccess, setIsModalOpen }) => {
  return (
    <div className="text-centre">
      <img
        src={success}
        alt="success-img"
        width="320px"
        className="application-success-image"
      />
      <Title level={4} style={{ maxWidth: "501px" }}>
        Thank you for signing up on the waitlist, we will reach out to you soon.
      </Title>{" "}
      {/* <Button
        // variant="primary"
        size="large"
        className="contained_button success-submit"
        onClick={() => {
          setIsModalOpen({ variant: "seeker", state: false });
          setSuccess(false);
          //   setSuccess(false);
        }}
      >
        Continue to Home page
      </Button> */}
    </div>
  );
};

export default Success;
