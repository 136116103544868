import { Form, Input } from "antd";
import React, { useState } from "react";
import { saveForm } from "../../API/waitlist";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../Config/firebase";

const EmployerApplication = ({ setSuccess }) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const onFinish = (values) => {
    saveForm({
      formData: values,
      type: "employer",
      setSuccess,
      setLoading,
    });
    logEvent(analytics, `employer_onboarding_completed`);
  };

  return (
    <div className="employer-application-container">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          requiredMarkValue: false,
        }}
        requiredMark={false}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter your name",
            },
          ]}
        >
          <Input placeholder="Enter your name" size="large" />
        </Form.Item>
        <Form.Item
          label="Email Address"
          rules={[
            {
              required: true,
              message: "Please enter your Email",
            },
            {
              pattern:
                "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
              message: "Please enter a valid email address",
            },
          ]}
          name="email"
        >
          <Input placeholder="Enter your email address" size="large" />
        </Form.Item>
        <Form.Item
          label="LinkedIn Profile"
          rules={[
            {
              required: true,
              message: "Please enter your LinkedIn profile Url",
            },
          ]}
          name="linkedinUrl"
        >
          <Input placeholder="Paste your profile link" size="large" />
        </Form.Item>
        <Form.Item label="Referred By (optional)" rules={[]} name="referredBy">
          <Input placeholder="Enter the name of your referral" size="large" />
        </Form.Item>

        <div style={{ width: "100%" }}>
          <button
            className={
              isLoading
                ? "disabled-btn application-submit-button"
                : "application-submit-button"
            }
            htmlType="submit"
            disabled={isLoading}
            // disabled={isLoading}
          >
            Join our Waitlist
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EmployerApplication;
