export const ApiStatics = {
  default: {
    default: "default",
  },
  auth: {
    auth: "auth",
    signUp: "sign-up",
  },

  saveForm: {
    save: "save-website-form",
  },
  userTypes: {
    candidate: "candidate",
  },
  jobs: {
    jobs: "jobs",
    business: "business",
    miniature: "miniature",
    industryTypes: "industry-types",
    experience: "experience-levels",
    functions: "job-functions",
    unmached: "unmatched",
    matched: "matched",
    apply: "apply",
  },
};
