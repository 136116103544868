import React, { useEffect } from "react";
import Banner from "./Components/Banner";
import Maintain from "./Components/Maintain";
import Discover from "./Components/Discover";
import FAQ from "./Components/FAQ";
import Footer from "./Components/Footer";
import { navItemHighlighter } from "../../Utils/helper";
import { useLocation } from "react-router-dom";
import TrustedBy from "./Components/TrustedBy";

const Home = () => {
  const { state } = useLocation();
  const href = window.location.href.substring(
    window.location.href.lastIndexOf("#") + 1
  );
  useEffect(() => {
    const element = document.getElementById(href);

    const elementOffset = element?.getBoundingClientRect().top + window.scrollY;
    //  + el.getBoundingClientRect().top
    const isAnotherPage = false;
    navItemHighlighter(isAnotherPage, href, elementOffset);
    if (element) {
      window.scrollTo({
        top: elementOffset - 80,
        left: 0,
        behavior: "smooth",
      });
      // window.scrollTo(0, );
      // element.scrollIntoView({ behavior: "smooth" });
    }
  }, [href]);
  return (
    <div>
      <Banner />
      <Maintain />

      <Discover />
      {/* <Testimonials /> */}

      <FAQ />
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
