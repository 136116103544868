import axios from "axios";
import { ApiStatics } from "../Utils/ApiStatics";

export const joinWaitlist = async ({
  formData,
  type,
  setSuccess,
  setLoading,
  toast,
  toastMessage,
}) => {
  setLoading(true);
  const config = {
    headers: {
      origin: "",
    },
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BACKEND_URL2}/${ApiStatics.auth.auth}/${ApiStatics.userTypes.candidate}/${ApiStatics.auth.signUp}`,
      { ...formData, type }
    );
    // if (response.data.message === "success") {
    setSuccess && setSuccess(true);
    setLoading && setLoading(false);
    toast && toast.success(toastMessage);
    // } else {
    //   toast && toast.error("Error sending your request");
    // }

    setLoading && setLoading(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  } catch (err) {
    console.log("err", err);
    setLoading && setLoading(false);
  }
};
export const saveForm = async ({
  formData,
  type,
  setSuccess,
  setLoading,
  toast,
  toastMessage,
}) => {
  setLoading(true);
  const config = {
    headers: {
      origin: "",
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.default.default}/${ApiStatics.saveForm.save}`,
    { ...formData, type, profileUrl: formData.linkedinUrl }
  );
  if (response.data.message === "success") {
    setSuccess && setSuccess(true);
    setLoading && setLoading(false);
    toast && toast.success(toastMessage);
  } else {
    toast && toast.error("Error sending your request");
  }

  setLoading && setLoading(false);
  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

export const getAllJobFunctions = async ({ jobFunctionNameHandler }) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: "Bearer " + token,
      // Accept: "*/*",
    },
  };

  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_BACKEND_URL2}/${ApiStatics.jobs.jobs}/${ApiStatics.jobs.functions}/?randomOrder=true`,
      config
    );

    console.log("cat fetxh", response.data.data.data);
    jobFunctionNameHandler && jobFunctionNameHandler(response.data.data.data);
    return {
      success: true,
      data: response.data.data.data,
      total: response.data.total,
    };
  } catch (error) {
    //   toast.error(error.response.data.message);
    // setValueLoading({ ...valuesLoading, jobFunction: false });
    console.log("error", error);
    // const status = error.response.status;
    // expireTokenHandler(status);
    return {
      success: false,
      // error: error.message,
    };
  }
};

export const getAllExperienceLevels = async ({ experienceNameHandler }) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: "Bearer " + token,
      Accept: "*/*",
    },
  };
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_BACKEND_URL2}/${ApiStatics.jobs.jobs}/${ApiStatics.jobs.experience}`,
      config
    );

    experienceNameHandler && experienceNameHandler(response.data.data.data);

    return {
      success: true,
      data: response.data.data.data,
      total: response.data.total,
    };
  } catch (error) {
    //   toast.error(error.response.data.message);
    // setValueLoading({ ...valuesLoading, experienceLevel: false });
    console.log("error", error);
    // const status = error.response.status;
    // expireTokenHandler(status);
    return {
      success: false,
      // error: error.message,
    };
  }
};
