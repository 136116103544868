import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import "./index.scss";
import arrow from "../../../../Assets/Icons/arrow.svg";
import blueArrow from "../../../../Assets/Icons/blueArrow.svg";
import stylishUnderline from "../../../../Assets/Icons/underline-2.svg";
import bannerNew from "../../../../Assets/Images/bannerNew.svg";
import mobileBanner from "../../../../Assets/Images/mobileBanner.png";
import { fancyUlTextRenderer } from "../../../../Utils/utils";
import JoinWaitlistModal from "../../../../Components/JoinWaitlist";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../Config/firebase";
import TrustedBy from "../TrustedBy";

const Banner = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    variant: "seeker",
    state: false,
  });
  const [isMobileDevice, setIsMobileDevice] = useState(
    window.innerWidth <= 768
  );

  const handleOk = () => setIsModalOpen({ variant: "seeker", state: false });
  const handleCancel = () =>
    setIsModalOpen({ variant: "seeker", state: false });
  const handleClicker = (type) => {
    setIsModalOpen({ variant: type, state: true });
    logEvent(analytics, `${type}_onboarding_started`);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="home-banner-wrapper" id="home">
      <div className="home-banner-container">
        <Title
          level={1}
          className="maintain_heading"
          style={{ marginBottom: "0.2em" }}
        >
          Maintain{" "}
          {fancyUlTextRenderer(stylishUnderline, "Anonymity", "#5d5bf6")} As You
        </Title>
        <Title
          level={1}
          className="maintain_heading"
          style={{ marginBottom: "0.2em" }}
        >
          Confidentially Search For Your
        </Title>
        <Title level={1} className="maintain_heading">
          Next <span className="secondary-text"> Leadership Role</span>
        </Title>
        <Title level={4} style={{ marginTop: "0px" }} className="banner_text">
          Approved job postings and vetted leadership candidates remain
          anonymous until a match has been initiated.
        </Title>
        <div>
          <div className="banner-btn-wrapper" style={{ marginTop: "40px" }}>
            <button
              size="large"
              className="contained_button"
              onClick={() => handleClicker("seeker")}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                I’m a Leader
                <img
                  src={arrow}
                  // preview={false}
                  width="8px"
                  height="25px"
                  style={{ marginLeft: "8px" }}
                  alt="arrow"
                />
              </div>
            </button>
            <button
              // type="primary"
              className="outlined_button"
              size="large"
              // icon={"I’m Hiring"}
              onClick={() => handleClicker("employer")}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                I’m Recruiting
                <img
                  src={blueArrow}
                  // preview={false}
                  width="8px"
                  height="25px"
                  style={{ marginLeft: "8px" }}
                  alt="arrow"
                />
              </div>
            </button>
          </div>
          <div className="waitlist-join-text">
            {" "}
            <Title
              level={5}
              style={{ marginTop: "15px" }}
              className="banner_text"
            >
              {fancyUlTextRenderer(
                stylishUnderline,
                "Join the Waitlist",
                "#5d5bf6"
              )}
            </Title>
          </div>
        </div>
        <div className="trusted-plus-images-container">
          <TrustedBy />
          <div className="banner-img-container" style={{ marginTop: "80px" }}>
            <img
              src={!isMobileDevice ? bannerNew : mobileBanner}
              preview={false}
              alt="banner-1"
            />
          </div>
        </div>
      </div>
      <JoinWaitlistModal
        isModalOpen={isModalOpen.state}
        handleOk={handleOk}
        handleCancel={handleCancel}
        defaultValue={isModalOpen.variant}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default Banner;
