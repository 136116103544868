import React from "react";
import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import logoWhite from "../../../../Assets/Images/full-logo-white.svg";
import { Button, Typography } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
const Footer = () => {
  const { pathname } = useLocation();
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const optional = pathname === "/about";
  return (
    <div
      className={optional ? "optional-footer-class footer" : "footer"}
      id="footer"
    >
      <div className="footer-wrapper">
        <img src={logoWhite} width="270px" height="56px" alt="stealth-logo" />

        <div className="footer-contact-info">
          <Typography className="footer-email">
            <div className="footer-links-container">
              <Link to="/privacy-policy" id="privacy-policy">
                Privacy Policy
              </Link>
              <a href="mailto:support@stealthjobs.co">support@stealthjobs.co</a>
            </div>{" "}
          </Typography>
          <Button
            shape="circle"
            icon={<ArrowUpOutlined />}
            size="large"
            onClick={scrollTop}
            className="footer-to-top-btn"
          />
        </div>
      </div>
    </div>
  );
};
export default Footer;
