import Title from "antd/es/typography/Title";
import React, { useEffect } from "react";
import stylishUnderline from "../../Assets/Icons/stylish-underline.svg";
import { fancyUlTextRenderer } from "../../Utils/utils";
import "./style.scss";
import { whyWeBuiltStaticText } from "../../Utils/staticData";
import owner from "../../Assets/Images/owner2.png";
import { navItemHighlighter } from "../../Utils/helper";
import Footer from "../Home/Components/Footer";
import { useLocation } from "react-router-dom";

const WhyWeBuilt = () => {
  const isAnotherPage = true;
  const { pathname } = useLocation();
  useEffect(() => {
    navItemHighlighter(isAnotherPage, pathname);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="why-we-built-container">
      <Title level={1} className="why-we-built-heading">
        Why We {fancyUlTextRenderer(stylishUnderline, "Built", "#5D5BF6")} This
      </Title>
      <div className="why-we-built-body">
        {/* <div className="built-text-wrapper">
          {whyWeBuiltStaticText.map((text) => (
            <p className="why-we-built-text">{text}</p>
          ))}
        </div> */}
        {/* <div className="wrap">
          <img src={owner} alt="owner" width="100%" className="owner-img" />
        </div> */}
      </div>
      <section>
        <div class="wrap">
          <img src={owner} alt="owner" width="90%" className="owner-img" />
        </div>

        <div>
          {whyWeBuiltStaticText.map((text, index) => (
            <p
              className="why-we-built-text"
              style={{ paddingTop: index === 0 && "0px" }}
            >
              {text}
            </p>
          ))}
        </div>
      </section>
    </div>
  );
};

export default WhyWeBuilt;
