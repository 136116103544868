import React from "react";
import "./index.scss";
import { Grid, Typography } from "antd";
import Marquee from "react-marquee-slider";
import { trustedEmployers } from "../../../../Utils/staticData";

const TrustedBy = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isMobile = screens.xs;
  return (
    <div className="trusted-container">
      <Typography style={{ textAlign: "center" }}>
        Trusted by leaders, venture backed companies, and leading executive
        search experts
      </Typography>
      <Marquee velocity={isMobile ? 10 : 30}>
        {trustedEmployers.map((logo) => (
          <img
            src={logo}
            alt="customer-logo"
            height="50px"
            className="trusted-by-logo"
          />
        ))}
      </Marquee>
    </div>
  );
};

export default TrustedBy;
