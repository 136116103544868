import React from "react";
import "./style.scss";
import { Grid } from "antd";

import DeskBar from "./DeskBar";
import MobileBar from "./MobileBar";

const Navbar = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isBigScreen = screens.xl;
  return isBigScreen ? <DeskBar /> : <MobileBar />;
};

export default Navbar;
