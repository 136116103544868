import { Image } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import "./index.css";

const IconText = ({ icon, title }) => {
  return (
    <div className="icon-text-container">
      <div className="icon-wrapper">
        <Image src={icon} preview={false} width="40px" height="40px" />
      </div>
      <Title level={4} style={{ textAlign: "center" }}>
        {title}
      </Title>
    </div>
  );
};

export default IconText;
