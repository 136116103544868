import Title from "antd/es/typography/Title";
import React from "react";
import "./index.scss";
import IconText from "./Components/IconText";
import { anonimityCards } from "../../../../Utils/staticData";
import { fancyUlTextRenderer } from "../../../../Utils/utils";
import stylishUnderline from "../../../../Assets/Icons/underline-2.svg";

const Maintain = () => {
  return (
    <div className="maintain-container" id="what-we-do">
      <Title
        level={1}
        style={{ marginTop: "0px", marginBottom: "0" }}
        className="banner_heading"
      >
        Your Gateway to
      </Title>
      <Title
        level={1}
        style={{ marginTop: "0px", marginBottom: "0.5em !important" }}
        className="banner_heading"
      >
        Exclusive{" "}
        {fancyUlTextRenderer(stylishUnderline, "Leadership", "#5D5BF6")}{" "}
        Opportunities
      </Title>
      <Title level={4} className="banner_text" style={{ marginTop: "0px" }}>
        Redefining Recruitment with Efficiency, Confidentiality, and Inclusivity
      </Title>

      <div className="maintain-icons-container">
        {anonimityCards.map(({ icon, title }) => (
          <IconText icon={icon} title={title} />
        ))}
      </div>
    </div>
  );
};

export default Maintain;
