import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import "./style.scss";
import { fancyUlTextRenderer } from "../../../../Utils/utils";
import stylishUnderline from "../../../../Assets/Icons/stylish-underline.svg";
import { Card } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { faq_Config } from "../../../../Utils/staticData";

const FAQ = () => {
  const [showTextArray, setShowTextArray] = useState(
    Array(faq_Config.length + 1).fill(true)
  );

  const toggleTextVisibility = (index) => {
    const updatedShowTextArray = [...showTextArray];
    updatedShowTextArray[index] = !updatedShowTextArray[index];
    setShowTextArray(updatedShowTextArray);
  };
  const midIndex = Math.ceil(faq_Config.length / 2);
  return (
    <div className="faq-container" id="faq">
      <Title level={1} className="faq-heading">
        Frequently Asked Questions{" "}
        {fancyUlTextRenderer(stylishUnderline, "(FAQ’s)", "#5D5BF6")}{" "}
      </Title>
      <div className="faq-card-wrapper">
        <div className="faq-column">
          {faq_Config.slice(0, midIndex).map((item) => (
            <Card
              className="faq-card"
              key={item?.id}
              onClick={() => toggleTextVisibility(item?.id)}
            >
              <div className="content">
                <div className="faq-card-heading">{item?.heading}</div>
                {!showTextArray[item?.id] && (
                  <div className="faq-card-text">{item?.text}</div>
                )}
              </div>
              <div className="icon">
                <p className={`${showTextArray[item?.id] ? "" : "hide"}`}>
                  {showTextArray[item?.id] ? (
                    <PlusOutlined />
                  ) : (
                    <MinusOutlined />
                  )}
                </p>
              </div>
            </Card>
          ))}
        </div>
        <div className="faq-column">
          {faq_Config.slice(midIndex).map((item) => (
            <Card
              className="faq-card"
              key={item?.id}
              onClick={() => toggleTextVisibility(item?.id)}
            >
              <div className="content">
                <div className="faq-card-heading">{item?.heading}</div>
                {!showTextArray[item?.id] && (
                  <div className="faq-card-text">{item?.text}</div>
                )}
              </div>
              <div className="icon">
                <p className={`${showTextArray[item?.id] ? "" : "hide"}`}>
                  {showTextArray[item?.id] ? (
                    <PlusOutlined />
                  ) : (
                    <MinusOutlined />
                  )}
                </p>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
