import * as React from "react";
import { useRoutes } from "react-router-dom";

import HomepageLayout from "./Layouts/Homepage";
import Home from "./Pages/Home";
import WhyWeBuilt from "./Pages/WhyWeBuilt";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import NewsLetter from "./Pages/Newsletter";

function Router() {
  let element = useRoutes([
    {
      path: "/",
      element: <HomepageLayout />,
      children: [
        { path: "", element: <Home /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/newsletter", element: <NewsLetter /> },

        // { path: "*", element: <Redirect />ok },
        // { path: "/404", element: <Home /> },
        // <Route path='*' exact={true} component={My404Component} />
        { path: "/about", element: <WhyWeBuilt /> },
      ],
    },
  ]);
  return element;
}

export default Router;
