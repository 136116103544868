import { Form, Input, Select, Skeleton } from "antd";
import React from "react";

const SeekerApplication = ({
  loadSkeleton,
  onFinish,
  jobFunctionName,
  experienceName,
  setExperienceLevel,
  isLoading,
  setFunctionValue,
}) => {
  const [form] = Form.useForm();

  return (
    <div className="seeker-application-container">
      {loadSkeleton ? (
        <>
          <Skeleton active />
          <Skeleton style={{ marginTop: "16px" }} active />
          <Skeleton style={{ marginTop: "16px" }} active />
          <Skeleton style={{ marginTop: "16px" }} active />
        </>
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            requiredMarkValue: false,
          }}
          requiredMark={false}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter your name",
              },
            ]}
          >
            <Input placeholder="Enter your name" size="large" />
          </Form.Item>
          <Form.Item
            label="Email Address"
            rules={[
              {
                required: true,
                message: "Please enter your Email",
              },
              {
                pattern:
                  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
                message: "Please enter a valid email address",
              },
            ]}
            name="email"
          >
            <Input placeholder="Enter your email address" size="large" />
          </Form.Item>
          <Form.Item
            label="LinkedIn Profile"
            rules={[
              {
                required: true,
                message: "Please enter your LinkedIn profile Url",
              },
            ]}
            name="linkedinUrl"
          >
            <Input placeholder="Paste your profile link" size="large" />
          </Form.Item>
          <Form.Item
            label="Referred By (optional)"
            rules={
              [
                // {
                //   required: true,
                //   message: "Please enter your LinkedIn profile Url",
                // },
              ]
            }
            name="referredBy"
          >
            <Input placeholder="Enter the name of your referral" size="large" />
          </Form.Item>

          <Form.Item
            label="Primary Job Function"
            rules={[
              {
                required: true,
                message: "Please select job function",
              },
            ]}
            name="jobFunctionId"
          >
            <Select
              placeholder={"Select primary job function"}
              options={jobFunctionName}
              size="large"
              onChange={(e, value) => setFunctionValue(value.label)}
            />
          </Form.Item>

          <Form.Item
            label="Experience Level"
            rules={[
              {
                required: true,
                message: "Select experience level",
              },
            ]}
            name="experienceLevel"
          >
            <Select
              placeholder={"Select experience level"}
              options={experienceName}
              onChange={(e, value) => setExperienceLevel(value.label)}
              size="large"
            />
          </Form.Item>

          <div style={{ width: "100%" }}>
            <button
              className={
                isLoading
                  ? "disabled-btn application-submit-button"
                  : "application-submit-button"
              }
              htmlType="submit"
              disabled={isLoading}
            >
              Join our Waitlist
            </button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default SeekerApplication;
